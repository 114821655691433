export const shareFB = (link, title) => {
  window.FB.ui({
    method: 'share',
    href: link,
    link: link,
    quote: title,
  });
};

export const shareLinkedin = (e, url) => {
  e.preventDefault();
  const config = {
    height: 620,
    width: 750,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    left: window.innerWidth / 2 - 375,
    top: window.innerHeight / 2 - 310,
  };

  window.open(
    `https://www.linkedin.com/shareArticle?mini=true&url=${url}&source=Expoint`,
    '',
    Object.keys(config)
      .map((key) => `${key}=${config[key]}`)
      .join(', ')
  );
};

export const copyLink = (link, enqueueSnackbar, intl) => {
  try {
    navigator.clipboard.writeText(link);
    enqueueSnackbar(
      intl.formatMessage({
        id: 'snackbar.success.linkCopied',
        defaultMessage: 'The link has been copied.',
      }),
      {
        variant: 'success',
      }
    );
  } catch (e) {
    enqueueSnackbar(
      intl.formatMessage({
        id: 'snackbar.error.somethingWentWrong',
        defaultMessage: 'Something went wrong.',
      }),
      {
        variant: 'error',
      }
    );
  }
};
