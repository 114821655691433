import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import Facebook from 'src/img/icons/Facebook';
import Linkedin from 'src/img/icons/Linkedin';
import Copy from 'src/img/icons/Copy';
import { getNavigationUrl } from 'src/utils/history';
import { shareFB, shareLinkedin, copyLink } from 'src/utils/share';

const useStyles = makeStyles(({ palette }) => ({
  popover: {
    zIndex: 100,
    marginTop: 10,
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '0',
      left: '90%',
      width: '0',
      height: '0',
      border: '10px solid transparent',
      borderBottomColor: '#FFF',
      borderTop: '0',
      marginLeft: '-12px',
      marginTop: '-10px',
    },
  },
  paper: {
    opacity: 1,
    backgroundColor: palette.primary.main,
    width: 180,
    backgroundColor: '#fff',
    boxShadow: '-3px 3px 10px 0 rgba(0,0,0,0.22)',
    borderRadius: 6,
    '@media (max-width: 900px)': {
      boxShadow: '-3px 3px 10px 0 rgba(0,0,0,0.22)',
    },
  },
  menuItem: {
    width: '100%',
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 14,
    color: palette.primary.dark,
    paddingLeft: 10,
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
    '& p': {
      marginLeft: 0,
    },
  },
  icon: {
    minWidth: 25,
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
  fbIcon: {
    marginLeft: -5,
    minWidth: 30,
  },
}));

const SharePopover = ({
  anchorEl,
  open,
  closeMenu,
  job: { id: jobId, title, companyName, country, city },
  enqueueSnackbar,
  intl,
}) => {
  const classes = useStyles();
  const link =
    process.env.FRONT_URL +
    getNavigationUrl(`/job/${encodeURIComponent(jobId)}`);

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      className={classes.popover}
      placement='bottom-end'
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={{ enter: 300, exit: 100 }}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={closeMenu}>
              <MenuList
                autoFocusItem={open}
                id='menu-list-grow'
                onKeyDown={handleListKeyDown}
              >
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => {
                    shareFB(link, title);
                  }}
                >
                  <div className={clsx(classes.icon, classes.fbIcon)}>
                    <Facebook color='#4267B2' />
                  </div>
                  <p>
                    <FormattedMessage
                      id='sharePopover.shareFacebook'
                      defaultMessage='Share on Facebook'
                    />
                  </p>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={(e) => shareLinkedin(e, link)}
                >
                  <div className={classes.icon}>
                    <Linkedin color='#007DBA' />
                  </div>
                  <p>
                    <FormattedMessage
                      id='sharePopover.shareLinkedin'
                      defaultMessage='Share on Linkedin'
                    />
                  </p>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => copyLink(link, enqueueSnackbar, intl)}
                >
                  <div className={classes.icon}>
                    <Copy />
                  </div>
                  <p>
                    <FormattedMessage
                      id='sharePopover.copyLink'
                      defaultMessage='Copy Link'
                    />
                  </p>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default SharePopover;
